import React from 'react';
import Card from "./Card"
import Carousel from "react-elastic-carousel";
import Bandeja from "../assets/Accesorios-Consumibles/band_met.jpg"
import Cesta from "../assets/Accesorios-Consumibles/cesta_ruedas.png"
import Etiquetadora from "../assets/Accesorios-Consumibles/etiq.jpg"
import SuTurno from "../assets/Accesorios-Consumibles/pant_turno.jpg"
import Portaprecios from "../assets/Accesorios-Consumibles/portaprecios.jpg"
import dos from "../assets/Accesorios-Consumibles/02.png"
import cinco from "../assets/Accesorios-Consumibles/05.jpg"
import seis from "../assets/Accesorios-Consumibles/06.jpg"

const Accesorios = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2 },

  ];

  const elements1 = [
    "Bandejas de metacrilato.",
    "Cesta con ruedas.",
    "Etiquetadoras.",
    "Pantallas su turno.",
    "Portaprecios.",
  ]

  const elements2 = [
    "Rollos papel registradoras y TPV'S todas las medidas.",
    "Cintas y cartuchos tinta y toners para impresoras. Originales, reciclados y compatibles.",
    "Rollos de fax.",
    "Etiquetas térmicas.",
    "Rollos film alimenticio.",
    "Rollos precinto.",
    "Papel fotocopiadora.",
    "Papel continuo.",
    "Rollos su turno.",
    "Nuevo material de oficina y papelería."
  ]

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
            <Card style={{backgroundImage: `url(${Bandeja})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
            <Card style={{backgroundImage: `url(${Cesta})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
            <Card style={{backgroundImage: `url(${Etiquetadora})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
            <Card style={{backgroundImage: `url(${SuTurno})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
            <Card style={{backgroundImage: `url(${Portaprecios})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">Accesorios</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements1.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6 bg-white">
        <Carousel breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${dos})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${cinco})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${seis})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
          </Carousel>
        </div>
        <div className="col-12 col-lg-6 py-4">
          <div className="row pt-5 pb-3 px-lg-5">
            <h1 className="">Consumibles</h1>
          </div>
          <div className="row px-lg-5">
            <div className="col text-white px-2 m-0">
              {elements2.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
            </div>
          </div>
        </div>
      </div>
      </div>
</React.Fragment>
  );
};

export default Accesorios;