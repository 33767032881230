import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import "react-bootstrap";
import puntdejoc from "../assets/icons/puntdejoc.jpg";
import laconsigna from "../assets/icons/laconsigna.jpg";
import bipbip from "../assets/icons/bipbip.jpg";
import logoCanJoan from "../assets/icons/logoCanJoan.png";
import bingomenorca from "../assets/icons/bingomenorca.png";
import eroski from "../assets/icons/eroski.jpg";
import lacreme from "../assets/icons/lacreme.png";
import mercatolivar from "../assets/icons/mercatolivar.jpg";
import peregarau from "../assets/icons/peregarau.png";
import mercatsantacatalina from "../assets/icons/mercatsantacatalina.jpg";
import guinneshouse from "../assets/icons/guinneshouse.jpg";
import hiperfresc from "../assets/icons/hiperfresc.png";
import versalles from "../assets/icons/versalles.jpg";
import fornaris from "../assets/icons/fornaris.jpg";
import logoCanPelut from "../assets/icons/logoCanPelut.png";
import logoRosales from "../assets/icons/logoRosales.jpg";
import barbocados from "../assets/icons/barbocados.png";
import panaderiaestacio from "../assets/icons/panaderiaestacio.jpg";
import megafungames from "../assets/icons/megafungames.png";
import fruteriabambu from "../assets/icons/fruteriabambu.jpg";
import fruteriahierbabuena from "../assets/icons/fruteriahierbabuena.jpg";

const items = [
  {
    src: logoCanJoan,
    altText: "CAN JOAN DE S'AIGO",
    caption: "Fundada l'any 1700",
  },
  {
    src: logoCanPelut,
    altText: "PIZZERIA CAN PELUT",
    caption: "Cocina de calidad y 100% italiana",
  },
  {
    src: bipbip,
    altText: "SUPERMERCADOS BIP BIP",
    caption: "Tu supermercado de confianza",
  },
  {
    src: puntdejoc,
    altText: "PUNT DE JOC",
    caption: "Apuesta por la diversión",
  },
  {
    src: laconsigna,
    altText: "CAFERTERÍA - PASTELERÍA LA CONSIGNA",
    caption: "Tu punto de encuentro en el Puerto de Andratx",
  },
  {
    src: lacreme,
    altText: "LA CRÈME",
    caption: "Charcuteria selecta",
  },
  {
    src: fornaris,
    altText: "PANADERÍA - PASTELERÍA FORNARIS",
    caption: "Productos artesanos en Palma de Mallorca",
  },
  {
    src: guinneshouse,
    altText: "GUINNESS HOUSE PALMA",
    caption: "Tu mejor sitio frente la Catedral de Palma",
  },
  {
    src: barbocados,
    altText: "BAR BOCADOS",
    caption: "No duden en probar sus hamburguesas...",
  },
  {
    src: panaderiaestacio,
    altText: "PANADERÍA SESTACIÓ",
    caption: "Una idea única en el mundo",
  },
  {
    src: fruteriabambu,
    altText: "FRUTERÍAS BAMBÚ",
    caption: "Producto local de máxima calidad",
  },
  {
    src: fruteriahierbabuena,
    altText: "FRUTERÍAS HIERBABUENA",
    caption: "Tus frutas y verduras frescas cada dia",
  },
  {
    src: megafungames,
    altText: "MEGA FUN GAMES",
    caption: "Muy cerca de ti",
  },
  {
    src: logoRosales,
    altText: "BINGO ROSALES",
    caption: "Línea!!! Bingo!!!",
  },
  {
    src: versalles,
    altText: "BINGO VERSALLES",
    caption: "Línea!!! Bingo!!!",
  },
  {
    src: bingomenorca,
    altText: "BINGO SANTA CATALINA",
    caption: "Línea!!! Bingo!!!",
  },
  {
    src: mercatolivar,
    altText: "COLABORACIÓN CON:",
    caption: "Mercat de l'Olivar",
  },
  {
    src: peregarau,
    altText: "COLABORACIÓN CON:",
    caption: "Mercat de Pere Garau",
  },
  {
    src: mercatsantacatalina,
    altText: "COLABORACIÓN CON:",
    caption: "Mercat de Santa Catalina",
  },
  {
    src: hiperfresc,
    altText: "HIPER FRESC",
    caption: "Aprop teu",
  },
  {
    src: eroski,
    altText: "EROSKI",
    caption: "Contigo",
  },
];

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="vh-50"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        style={{ borderColor: "black" }}
      >
        <div
          style={{
            backgroundImage: `url(${item.src})`,
            backgroundSize: "200px",
            backgroundPosition: "center",
            height: "50vh",
            backgroundRepeat: "no-repeat",
            marginBottom: "3%",
          }}
        ></div>
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.altText}
        />
      </CarouselItem>
    );
  });

  return (
    <React.Fragment>
      <h3 style={{ textAlign: "center", marginTop: "5%" }}>
        Confían en nosotros
      </h3>
      <h5 style={{ textAlign: "center" }}>SCA, con el pequeño comercio</h5>
      <Carousel
        className="vh-50"
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </React.Fragment>
  );
};

export default Slider;
