import React from "react";

const MyMap = () => {
  return (
    <div className="container-fluid">
      <iframe
        title="Map SCA (Sistemas de Control y Accesorios)"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12296.13075204941!2d2.6743646!3d39.6039461!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x37e255d84364f7bc!2sSCA%2C%20Sistemas%20de%20Control%20y%20Accesorios!5e0!3m2!1ses!2ses!4v1611665307823!5m2!1ses!2ses"
        frameBorder={0}
        style={{ width: "100%", height: "450px" }}
        allowFullScreen={false}
        aria-hidden={false}
      />
    </div>
  );
};

export default MyMap;
