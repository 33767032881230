import React from 'react';
import Card from "./Card"
import Carousel from "react-elastic-carousel";
import General from "../assets/sistemasSeguridad/vig_vigil_1.jpg"
import Todo from "../assets/sistemasSeguridad/todo.png"
import Antihurto1 from "../assets/sistemasSeguridad/antihurto_1.jpg"
import Antihurto2 from "../assets/sistemasSeguridad/antihurto_2.jpg"
import ControlHorario1 from "../assets/sistemasSeguridad/controlHorario1.png"
import ControlHorario2 from "../assets/sistemasSeguridad/controlHorario2.png"
import ControlHorario3 from "../assets/sistemasSeguridad/controlHorario3.png"
import ControlHorario4 from "../assets/sistemasSeguridad/controlHorario4.png"



const SistemasSeguridad = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2 },

  ];

  const elements1 = [
    "Gran variedad de sistemas, con diferentes modelos de grabadoras y de cámaras, tanto para interior, como exterior, con sistema IR para grabación nocturna, zoom óptico y cabinas calefactadas (sistema anti-vaho).",
  ]

  const elements2 = [
    "Diversos tipos de sistemas de paneles anti hurto de diferentes tamaños, materiales. Con posibilidad de montar una, dos o tres antenas."
  ]

  const elements3 = [
    "Control del horario y acceso mediante diversos sistemas, como por ejemplo el marcaje mediante huella digital."
  ]
  
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
            <Card style={{backgroundImage: `url(${General})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
            <Card style={{backgroundImage: `url(${Todo})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
              {/*<Card style={{backgroundImage: `url(${Camara1})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "75%", backgroundPosition:"center"}}/> */}
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">Sistemas de vídeovigilancia</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements1.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6 bg-white">
        <Carousel breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${Antihurto1})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Antihurto2})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${Frontal})`, backgroundSize: "60%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "60%", backgroundPosition:"center"}}/> */}
          </Carousel>
        </div>
        <div className="col-12 col-lg-6 py-4">
          <div className="row pt-5 pb-3 px-lg-5">
            <h1 className="">Sistemas Antihurto</h1>
          </div>
          <div className="row px-lg-5">
            <div className="col text-white px-2 m-0">
              {elements2.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
            <Card style={{backgroundImage: `url(${ControlHorario1})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
            <Card style={{backgroundImage: `url(${ControlHorario2})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
            <Card style={{backgroundImage: `url(${ControlHorario3})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
            <Card style={{backgroundImage: `url(${ControlHorario4})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">Control horario y Acceso</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements3.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>

</React.Fragment>
  );
};

export default SistemasSeguridad;