import React from 'react';
import Card from "./Card"
import Carousel from "react-elastic-carousel";
import registradora1 from "../assets/registradoras/registradora1.jpg"
import registradora2 from "../assets/registradoras/registradora2.jpg"
import registradora3 from "../assets/registradoras/registradora3.jpg"

const Registradoras = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2 },

  ];

  const elements1 = [
    "Caja Registradora Alfanumérica.",
    "Impresora Térmica de 57 mm, 24 columnas.",
    "Visor de Operador de 1 Línea Alfanumérico.",
    "Cinta de Control Electrónica dinámica, de hasta 2000 líneas.",
    "7 Departamentos directos (14 con tecla de cambio de Nivel).",
    "Hasta 400 PLU (productos con descripción y precio).",
    "Cajón con 8 monederos y 4 billeteros.",
    "Medidas: 34 x 36 x 20 cm.",

  ]

  const elements2 = [
    "Caja Registradora ECR SAMPOS ER-060.",
    "1 Impresora Térmica 57 mm alta velocidad.",
    "Teclado con 28 Departamentos.",
    "Visor de Operador 1 Línea alfanumérico VFD.",
    "Visor de Cliente 1 Línea VFD.",
    "Memoria configurable: hasta 1000 Plu’s.",
    "Máximo 4200 Líneas de Cinta Electrónica.",
    "Control de hasta 8 Empleados.",
    "Modo de Entrenamiento de Empleados.",
    "Función Calculadora.",
    "ER-060S, cajón pequeño. 340 x 360 x 230 mm.",
    "ER-060L, cajón grande. 410 x 430 x 230 mm. (4 Billeteros y 8 Monederos en ambos casos).",
  ]

  const elements3 = [
    "Caja Registradora con Impresora Térmica 57 mm.",
    "Visor de Operador de 8 Líneas VFD.",
    "Teclado Plano con 78 Productos directos x 3 Niveles.",
    "1000 Plu’s, 10 Empleados, Control 50 mesas.",
    "1 Puerto USB para PC y 1 Puerto RS-232 para PC, Scanner, Balanza.",
    "2500 Líneas de Cinta de Control Electrónica.",
    "Lector de Tarjetas SD para guardar Informes y Programación.",
  ]
  
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${registradora1})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${Posterior})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Frontal})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "75%", backgroundPosition:"center"}}/> */}
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">CAJA REGISTRADORA ER-057/S</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements1.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6 bg-white">
        <Carousel breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${registradora2})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${Posterior})`, backgroundSize: "60%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Frontal})`, backgroundSize: "60%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "60%", backgroundPosition:"center"}}/> */}
          </Carousel>
        </div>
        <div className="col-12 col-lg-6 py-4">
          <div className="row pt-5 pb-3 px-lg-5">
            <h1 className="">CAJA REGISTRADORA ER-060</h1>
          </div>
          <div className="row px-lg-5">
            <div className="col text-white px-2 m-0">
              {elements2.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${registradora3})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${Posterior})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Frontal})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "75%", backgroundPosition:"center"}}/> */}
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">CAJA REGISTRADORA ER-159F</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements3.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
</React.Fragment>
  );
};

export default Registradoras;