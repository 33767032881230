import React from "react";
import logokitdigital from "../assets/icons/logokitdigital.png";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="container-fluid">
      <div
        className="row"
        style={{
          backgroundColor: "#0193DE",
          padding: "4rem",
          color: "white",
          justifyContent: "CENTER",
        }}
      >
        <h3 style={{ color: "white" }}>KIT DIGITAL PARA PYMES Y AUTÓNOMOS</h3>
        <p style={{ textAlign: "justify" }}>
          {" "}
          El KIT Digital para PYMES y autónomos es una iniciativa del Gobierno
          de España que tiene como objetivo subvencionar la implantación de
          soluciones digitales en cualquier tipo de negocio.
        </p>
        <center>
          <img
            src={logokitdigital}
            alt={"logo"}
            style={{
              marginTop: "25px",
              width: "100%",
              height: "auto",
              backgroundColor: "white",
            }}
          />
        </center>
      </div>
      <center>
        <Link to="/kitdigital">
          <button
            style={{
              borderColor: "white",
              alignItems: "center",
              marginBottom: "15px",
            }}
            type="button"
            className="btn btn-info"
          >
            MÁS INFORMACIÓN
          </button>
        </Link>
      </center>
      <div
        className="row"
        style={{
          backgroundColor: "#29166F",
          padding: "4rem",
          color: "white",
          justifyContent: "left",
        }}
      >
        <h5 style={{ color: "yellow" }}>¿Quienes somos?</h5>
        <p style={{ textAlign: "justify" }}>
          {" "}
          · En 2001 creamos el proyecto SCA, una empresa de distribución de
          productos que abarca un amplio abanico en el mercado y que está
          destinado principalmente al sector de la hostelería y alimentación.
        </p>
        <p style={{ textAlign: "justify" }}>
          {" "}
          · Desde el comienzo de nuestra actividad hemos basado nuestra
          actividad en dos grandes pilares: un producto de gran calidad y un
          impecable servicio técnico. Nuestra idea es que todos los clientes
          queden satisfechos con el producto y la rapidez del servicio, con la
          idea de que repitan en el futuro.
        </p>
        <p style={{ textAlign: "justify" }}>
          {" "}
          · Ofrecemos siempre una atención personalizada y adaptada a las
          necesidades de cada cliente, con atención postventa en horario
          comercial y hasta las 24 horas en los casos mas especiales.
        </p>
        <h6 style={{ color: "yellow" }}>
          SERVICIO TÉCNICO PROPIO 365 DÍAS, 24 HORAS
        </h6>
      </div>
    </div>
  );
};

export default Banner;
