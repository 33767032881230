import React from "react";
import ReactPlayer from "react-player";
import logokitdigital from "../assets/icons/logokitdigital.png";
import businessIntelligence from "../assets/pantallas/businnessInteligence.jpg";
import gestionProcesos from "../assets/pantallas/gestionProcesos.jpg";
import gestionclientes from "../assets/pantallas/gestionclientes.jpg";
import uno from "../assets/icons/uno.png";
import dos from "../assets/icons/dos.png";
import tres from "../assets/icons/tres.png";
import { Link } from "react-router-dom";

const CarteleriaDigital = () => {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div
          className="row"
          style={{
            backgroundColor: "#0193DE",
            padding: "4rem",
            color: "white",
            justifyContent: "CENTER",
          }}
        >
          <h3 style={{ color: "white" }}>KIT DIGITAL PARA PYMES Y AUTÓNOMOS</h3>
          <p style={{ textAlign: "justify" }}>
            {" "}
            El KIT Digital para PYMES y autónomos es una iniciativa del Gobierno
            de España que tiene como objetivo subvencionar la implantación de
            soluciones digitales en cualquier tipo de negocio.
          </p>
          <center>
            <img
              src={logokitdigital}
              alt={"logo"}
              style={{
                marginTop: "25px",
                width: "100%",
                height: "auto",
                backgroundColor: "white",
              }}
            />
          </center>
        </div>
        <div className="row ">
          <div className="col-12 col-md-6">
            <div className="row h-100 justify-content-center align-items-center position-relative">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LbkzY4PzoZg&t=4s"
                width="100%"
                height="100%"
                controls
                playing
                muted
                playbackRate={1.75}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div
              className="row"
              style={{
                padding: "4rem",
                color: "white",
                justifyContent: "center",
              }}
            >
              <h3
                style={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                ¿Qué es el programa Kit Digital?
              </h3>
              <p style={{ textAlign: "justify" }}>
                {" "}
                Es un <strong>programa de ayudas económicas</strong> iniciativa
                del Gobierno de España que ha puesto en marcha el Ministerio de
                Asuntos Económicos y Transformación Digital para impulsar la
                digitalización de Pymes y autónomos.<br></br>
                <br></br>
                La{" "}
                <strong>
                  financiación de las ayudas se efectúa con fondos del Plan de
                  Recuperación, Transformación y Resiliencia de España,
                  financiado
                </strong>{" "}
                por el Plan europeo <strong>Next Generation EU.</strong>
                <br></br>
                <br></br> Si tu negocio forma parte de una{" "}
                <strong>
                  pequeña empresa, microempresa o eres un trabajador autónomo
                </strong>{" "}
                de cualquier sector o tipología de negocio puedes acceder a
                estas ayudas. Solicita tu <strong>bono kit digital</strong> e
                inicia la{" "}
                <strong>
                  digitalización de tu negocio con la ayuda de un agente
                  digitalizador
                </strong>{" "}
                acreditado.
              </p>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{
            backgroundColor: "#29166F",
            padding: "4rem",
            color: "white",
            justifyContent: "center",
            marginTop: "5%",
          }}
        >
          <h3 style={{ color: "yellow", textAlign: "center" }}>
            ¿Qué soluciones digitales te ofrecemos?
          </h3>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              marginTop: "15px",
            }}
          >
            {" "}
            Desde SCA te ofrecemos las siguientes soluciones incluidas dentro de
            las categorías de soluciones de digitalización. Elige las que mejor
            se adapten a las necesidades de tu negocio.
          </p>
          {/* GESTIÓN DE PROCESOS  */}
          <div className="row " style={{ marginTop: "20px" }}>
            <div className="col-12 col-md-6">
              <div className="row">
                <h3 style={{ color: "yellow", marginTop: "5px" }}>
                  Gestión de Procesos <br></br>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  Solución TPV intuitivo, rápido y fiable. Hecho prácticamente a
                  medida, se ajusta perfectamente a las necesidades del personal
                  en sala, cocina y back-office. <br></br>
                  <br></br>· TPV Táctil.<br></br>· Comanderos móviles.<br></br>·
                  Cajones portamonedas inteligentes.<br></br>· Registro horario
                  personal.<br></br>· Pasarela de Pagos. <br></br>
                  <br></br>
                  <br></br>
                  Desde 500€
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row justify-content-center align-items-center position-relative">
                <img
                  src={gestionProcesos}
                  alt={"logo"}
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                  }}
                />
              </div>
            </div>
          </div>
          {/* BUSINESS INTELLIGENCE */}
          <div className="row ">
            <div className="col-12 col-md-6">
              <div className="row">
                <h3 style={{ color: "yellow", marginTop: "5px" }}>
                  Business Intelligence <br></br>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  Solución back-office completo y potente para tener un control
                  completo de tus proveedores, compras, existencias, ventas y
                  personal: <br></br>
                  <br></br>· Aplicación móvil con información en tiempo real de
                  ventas y ocupación de tu negocio.<br></br>· Acceso remoto al
                  back-office para gestionar todo tipo de datos e informes.
                  <br></br> · APP ALMACÉN: Gestión de entrada de mercancía,
                  inventario y regulación de stock desde un terminal móvil.
                  <br></br>
                  <br></br>
                  <br></br>
                  Desde 500€
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row justify-content-center align-items-center position-relative">
                <img
                  src={businessIntelligence}
                  alt={"logo"}
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                  }}
                />
              </div>
            </div>
          </div>

          {/* GESTIÓN DE CLIENTES  */}
          <div className="row ">
            <div className="col-12 col-md-6">
              <div className="row">
                <h3 style={{ color: "yellow", marginTop: "5px" }}>
                  Gestión de Clientes <br></br>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  Solución ágil e intuitiva para mejorar la calidad del servicio
                  al cliente desde la reserva hasta el pago. <br></br>
                  <br></br>· Ágora Booking (Gestor de Reservas).<br></br>· Agora
                  SmartMenu (Carta Digital).<br></br>· Facturas a Clientes.
                  <br></br>· Pedido y pago en mesa.<br></br>· TakeAway y
                  Delivery. <br></br>
                  <br></br>
                  <br></br>
                  Desde 500€
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row justify-content-center align-items-center position-relative">
                <img
                  src={gestionclientes}
                  alt={"logo"}
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* CUAL ES EL IMPORTE DE LAS AYUDAS */}
        <div className="row ">
          <div className="col-12 col-md-6">
            <div className="row">
              <h3
                style={{
                  color: "yellow",
                  marginTop: "20px",
                }}
              >
                ¿Cuál es el importe de las ayudas?
              </h3>
              <p style={{ textAlign: "justify" }}>
                {" "}
                Las ayudas se concederán por orden de inscripción.<br></br>
                <br></br>
                El programa Kit Digital comprende tres tramos para el importe
                máximo que destinarán a cada compañía dependiendo de su tamaño.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <img
                src={uno}
                alt={"logo"}
                style={{
                  marginTop: "25px",

                  backgroundColor: "white",
                }}
              />
              <p
                style={{
                  textAlign: "justify",
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
              >
                {" "}
                La primera convocatoria estará dirigida a pymes de entre 10 y
                menos de 50 trabajadores.<br></br>
                Siendo el importe del bono kit digital de hasta 12.000 euros.
              </p>
            </div>
            <div className="row">
              <img
                src={dos}
                alt={"logo"}
                style={{
                  marginTop: "25px",

                  backgroundColor: "white",
                }}
              />
              <p
                style={{
                  textAlign: "justify",
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
              >
                {" "}
                La segunda convocatoria irá destinada a pymes de entre 3 y menos
                de 10 empleados.<br></br>
                Siendo el importe del bono kit digital en este caso de hasta
                6.000 euros.
              </p>
            </div>
            <div className="row">
              <img
                src={tres}
                alt={"logo"}
                style={{
                  marginTop: "25px",

                  backgroundColor: "white",
                }}
              />
              <p
                style={{
                  textAlign: "justify",
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
              >
                {" "}
                La tercera convocatoria irá destinada a pymes y autónomos de
                entre 1 y 3 empleados.<br></br>
                Siendo el importe del bono kit digital en este último caso de
                hasta 2.000 euros.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row"
        style={{
          backgroundColor: "#0193DE",
          padding: "4rem",
          color: "white",
          justifyContent: "CENTER",
        }}
      >
        <h3 style={{ color: "white" }}>
          ¿Buscas un agente digitalizador para el Kit Digital?
        </h3>
        <p style={{ textAlign: "justify" }}>
          {" "}
          SCA está en proceso de homologación para ser agente digitalizador.
          Estaremos encantados de ayudarte en tu transformación digital. Ponte
          en contacto con nosotros y te asesoraremos sin ningún compromiso.
        </p>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#0193DE",
          color: "white",
          justifyContent: "CENTER",
        }}
      >
        <Link to="/contacto">
          <button
            style={{
              borderColor: "white",
              marginBottom: "15px",
            }}
            type="button"
            className="btn btn-info"
          >
            MÁS INFORMACIÓN
          </button>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default CarteleriaDigital;
