import React from 'react';
import carteleriaDigital from "../assets/carteleriaDigital/carteleriaDigital.mp4"
import { FiCoffee } from "react-icons/fi" 
import { IoFastFoodOutline, IoBeerOutline, IoIceCreamOutline } from "react-icons/io5";

const CarteleriaDigital = () => {
  const elements1 = [
    "Controle su publicidad a distancia.",
    "Ofertas.",
    "Promociones.",
    "Eventos.",
    "Paneles informativos.",
    "Totalmente personalizable.",   
  ]

  return (
  <div className="container-fluid">
  <div className="row justify-content-center flex-wrap-reverse bg-white">
    <div className="col rowTextHome" style={{ backgroundColor: "#0193DE"}}>
      <div className="row pt-5 pb-3 px-lg-5 " style={{}}>
        <h1 className="">CARTELERÍA DIGITAL</h1>
      </div>
      <div className="row px-lg-5">
        <div className="col text-white px-2 m-0">
          {elements1.map((text, key) => <div key={key}>
            {"✓ " + text}
          </div>)}
          <p style={{textAlign: "center", fontWeight: "bold", marginTop: "15%", fontSize: "19px"}}>Apto para cualquier tipo de negocio</p>
          <div className="col-12 col-md-auto mt-3 mt-md-0">
                <div className="nav justify-content-center">
                      <a className="d-flex align-items-center mx-3">
                        <IoFastFoodOutline color="white" size="23px"/>
                      </a>
                      <a className="d-flex align-items-center mx-3">
                        <IoBeerOutline color="white" size="23px"/>
                      </a>
                      <a className="d-flex align-items-center mx-3">
                        <IoIceCreamOutline color="white" size="23px"/>
                      </a>
                      <a className="d-flex align-items-center mx-3">
                        <FiCoffee color="white" size="23px"/>
                      </a>
                </div>
          </div>     
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="justify-content-center m-auto">
        </div>
      </div>
    </div>
    <div className="col-auto mx-0 px-0">
      <div className="row align-items-center h-100 p-lg-3">
        <video controlsList="nodownload" loop id="myVideo" className="headerVideo" src={carteleriaDigital} muted
               autoPlay controls>
          <source src={carteleriaDigital} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
        </video>
      </div>
    </div>
  </div>
</div>
  );
};

export default CarteleriaDigital;