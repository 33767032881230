import React from 'react';
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <div className="container footer p-1">
        <div className="row justify-content-between mt-2">
          <div className="col-12 col-md-auto mt-3 mt-md-0">
            <div className="">
                <ul className="nav justify-content-start">
                            <div className="">
                              <Link style={{color: "yellow"}}>2020 – SCA ® Valentin Gracia |</Link>
                            </div>
                            <li className="nav-item">
                              <Link style={{color: "yellow"}} to="/politicaPrivacidad">Aviso Legal  |</Link>
                            </li>
                            <li className="nav-item">
                              <Link style={{color: "yellow"}} to="/politicaPrivacidad">Política de privacidad |</Link>
                            </li>
                            <li className="nav-item">
                              <Link style={{color: "yellow"}} to="/politicaPrivacidad">Cookies</Link>
                            </li>
                  </ul>
            </div>
          </div>
            <div className="col-12 col-md-auto mt-3 mt-md-0">
              <div className="nav justify-content-center">
                <a className="d-flex align-items-center mx-3" href="https://www.facebook.com/scaBaleares">
                  <FaFacebookF color="white" size="20px"/>
                </a>
                <a className="d-flex align-items-center mx-3" href="https://www.instagram.com/sca_baleares">
                  <FaInstagram color="white" size="23px"/>
                </a>
              </div>
          </div>
        </div>
      </div>
  );
}

export default Footer;
