import React from 'react';
// import ContactForm from "./ContactForm";
import MyMap from './MyMap';
import {AiOutlineMail} from "react-icons/ai"
import { FaParking, FaMobileAlt } from "react-icons/fa"

const Contact = (props) => {
  const texts = [
    "C/Gran Vía Asima, 20, local 3.",
    "07009, Palma de Mallorca",
    "Illes Balears (Spain)",
    "Parking para clientes",
    "Tel. +34 971 91 00 54",
    "Email: tienda@sca-palma.com",
  ]

  return (
    <div className="container-fluid" style={{ minHeight: "70vh" }}>
      <div className="row h-100 py-5 justify-content-center">
        <div className="col-12 col-lg-5">
          <div className="row justify-content-center text-center pt-5 pb-3 px-lg-5">
            <h1>¿Podemos ayudarte? </h1>
          </div>
          <div className="row justify-content-center text-center px-lg-5">
            <div className="col text-white px-2 m-0">
              {texts.map((text, key) => <div key={key}>
                {text}
              </div>)}
              <FaParking/> <AiOutlineMail/> <FaMobileAlt/>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5 mt-5 mt-lg-0">
          {/* <ContactForm/> */}
          <MyMap/>
        </div>
      </div>
      {/* <MyMap/> */}
    </div>
  )
}

export default Contact;