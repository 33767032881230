import React, { useState } from 'react';
import { Button, Modal} from 'reactstrap';

const ModalExample = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(!localStorage.getItem("Cookies SCA (Sistemas de Control y Accesorios)"));

  const toggle = () => {
    localStorage.setItem("Cookies SCA (Sistemas de Control y Accesorios)", "true");
    setModal(!modal)
  };

  return (
    <div>
      <Modal isOpen={modal} className={className}>
        <div className="container rounded shadow" style={{ width: "90vw", backgroundColor: "#fff", color: '#5b7e96' }}>
          <div className="row py-4 px-4 px-md-5" style={{ fontSize: "20px" }}>
            TU PRIVACIDAD ES IMPORTANTE PARA NOSOTROS
          </div>
          <div className="row px-4 px-md-5">
            Esta web utiliza cookies para personalizar contenido y publicidad, proporcionar funcionalidades a las redes
            sociales, o analizar nuestro tráfico. Si sigues navegando consientes el uso de esta tecnología en nuestra
            web. Para más información visita nuestro apartado
            Cookies
          </div>
          <div className="row px-4 px-md-5">
            <Button className="my-3 mx-auto" style={{backgroundColor: "#0193de", borderColor: "blue"}} onClick={toggle}>Aceptar</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalExample;
