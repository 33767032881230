import React from "react";
import Card from "./Card";
import Carousel from "react-elastic-carousel";
import jupiterv10 from "../assets/balanzas/jupiterv10.jpg";
import kscale from "../assets/balanzas/kscale.jpg";
import balanzaTPV1 from "../assets/balanzas/balanzaTPV1.jpg";
import balanzaTPV2 from "../assets/balanzas/balanzaTPV2.jpg";
import jupiter10RL from "../assets/balanzas/jupiter10RL.jpg";
import euroescale20RL from "../assets/balanzas/euroscale20RL.jpg";

import conjuntoIndustrial1 from "../assets/balanzas/conjuntoIndustrial1.jpg";
import conjuntoIndustrial2 from "../assets/balanzas/conjuntoIndustrial2.jpg";
import conjuntoIndustrial3 from "../assets/balanzas/conjuntoIndustrial3.jpg";

import balanzafront from "../assets/balanzas/BalanzaFront.jpg";
import balanzaback from "../assets/balanzas/BalanzaBack.jpg";

const Balanzas = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2 },
  ];

  const elements1 = [
    "Balanza con tecnología PC.",
    "Panel táctil.",
    "Potente software de gestión.",
    "Control de distintas balanzas y distintas tiendas a la vez.",
    "Programa centralizado para control 1 o más tiendas.",
  ];

  const elements2 = [
    "Balanza de mostrador con impresora térmica,",
    "Reposición automática cero,",
    "Tecla de puesta a cero,",
    "Posibilidad de impresión inferior a la carga mínima conforme a las normas de calibración.",
    "Interconexión de hasta 10 Equipos vía Ethernet, compatibles todos los modelos de la gama en la misma red.",
    "Conexión Vía XDSL.",
    "Puerto de comunicaciones RS232.",
  ];

  const elements3 = [
    "Balanza sobre mostrador con impresora.",
    "Reposición automática cero.",
    "Tecla de puesta a cero.",
    "Posibilidad de impresión inferior a la carga mínima conforme a las normas de calibración.",
    "Arquitectura ilimitada de red, velocidad en las comunicaciones.",
    "Programa totalmente modular, creado por Grupo Epelsa.",
    "Balanza diseñada con tecnología PC.",
    "S.0. Linux ó Windows.",
    "Pantallas TFT de 12 pulgadas, Touch-Screen (Táctil) para usuario.",
    "Pantalla VGA 9” 16:9 para el cliente. (Opción de 12 pulgadas).",
  ];

  const elements4 = [
    "Equipos compactos y robustos.",
    "Llevan incorporado visor y plataforma.",
    "Preparados para trabajar y usar inmediatamente con distintas funcionalidades (Peso-Tara, Cuenta piezas y/o Control.+/-).",
    "Selección de productos industriales con diversas medidas de plataformas adecuadas para todos los espacios y un número amplio de visores con los que complementar dichas plataformas.",
  ];

  const elements5 = [
    "Balanza PC con pantalla táctil de 15” y pantalla cliente VGA de 9” ó 15”.",
    "Posibilidad de realizar Publicidad, ofertas.",
    "Impresora y etiquetadora térmica inteligente de 60mm.",
    "Tarjeta gráfica dedicada, Memoria RAM DDR III (4 Gb).",
    "Almacenamiento SSD.",
    "Comunicación Ethernet (Opción Wifi).",
    "S.0. Windows.",
    "Mantenimiento remoto con herramientas estandar de mercado.",
    "Plato de acero inoxidable.",
  ];

  return (
    <React.Fragment>
      <div className="container-fluid">
        <h4
          style={{
            backgroundColor: "#29166F",
            color: "yellow",
            textAlign: "center",
          }}
        >
          REGISTRO METROLÓGICO Nº 15M-009-R
        </h4>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-6 bg-white">
              <Carousel breakPoints={breakPoints}>
                <Card
                  style={{
                    backgroundImage: `url(${balanzafront})`,
                    backgroundSize: "65%",
                    backgroundPosition: "center",
                  }}
                />
                <Card
                  style={{
                    backgroundImage: `url(${balanzaback})`,
                    backgroundSize: "65%",
                    backgroundPosition: "center",
                  }}
                />
              </Carousel>
            </div>
            <div className="col-12 col-lg-6 py-4">
              <div className="row pt-5 pb-3 px-lg-5">
                <h1 className="">BALANZA PC CERTIFICADA</h1>
              </div>
              <div className="row px-lg-5">
                <div className="col text-white px-2 m-0">
                  {elements5.map((text, key) => (
                    <div key={key}>{"✓ " + text}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
            <Carousel className="carousel" breakPoints={breakPoints}>
              <Card
                style={{
                  backgroundImage: `url(${balanzaTPV1})`,
                  backgroundSize: "70%",
                  backgroundPosition: "center",
                }}
              />
              <Card
                style={{
                  backgroundImage: `url(${balanzaTPV2})`,
                  backgroundSize: "70%",
                  backgroundPosition: "center",
                }}
              />
            </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">BALANZA TPV</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
                {elements1.map((text, key) => (
                  <div key={key}>{"✓ " + text}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 bg-white">
            <Carousel breakPoints={breakPoints}>
              <Card
                style={{
                  backgroundImage: `url(${jupiterv10})`,
                  backgroundSize: "100%",
                  backgroundPosition: "center",
                }}
              />
              <Card
                style={{
                  backgroundImage: `url(${jupiter10RL})`,
                  backgroundSize: "100%",
                  backgroundPosition: "center",
                }}
              />
            </Carousel>
          </div>
          <div className="col-12 col-lg-6 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">GAMA JÚPITER</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
                {elements2.map((text, key) => (
                  <div key={key}>{"✓ " + text}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
            <Carousel className="carousel" breakPoints={breakPoints}>
              <Card
                style={{
                  backgroundImage: `url(${kscale})`,
                  backgroundSize: "100%",
                  backgroundPosition: "center",
                }}
              />
              <Card
                style={{
                  backgroundImage: `url(${euroescale20RL})`,
                  backgroundSize: "100%",
                  backgroundPosition: "center",
                }}
              />
            </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">GAMA EUROSCALE</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
                {elements3.map((text, key) => (
                  <div key={key}>{"✓ " + text}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 bg-white">
            <Carousel breakPoints={breakPoints}>
              <Card
                style={{
                  backgroundImage: `url(${conjuntoIndustrial1})`,
                  backgroundSize: "100%",
                  backgroundPosition: "center",
                }}
              />
              <Card
                style={{
                  backgroundImage: `url(${conjuntoIndustrial2})`,
                  backgroundSize: "100%",
                  backgroundPosition: "center",
                }}
              />
              <Card
                style={{
                  backgroundImage: `url(${conjuntoIndustrial3})`,
                  backgroundSize: "100%",
                  backgroundPosition: "center",
                }}
              />
            </Carousel>
          </div>
          <div className="col-12 col-lg-6 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">CONJUNTOS INDUSTRIALES</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
                {elements4.map((text, key) => (
                  <div key={key}>{"✓ " + text}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Balanzas;
