import React from 'react';
import Banner from './Banner';
import VideoBanner from "./VideoBanner"
import SliderClientes from "./SliderClientes"

const Home = () => {

  return (
    <React.Fragment>
    <div className="container-fluid">
      <VideoBanner/>
      <Banner/>
      <SliderClientes/>
    </div>
    </React.Fragment>
  );
}


export default Home;
