import React, { useState } from "react";
import { Collapse } from "reactstrap";
import sca_logo from "../assets/icons/sca_logo.jpg";
import { Link, NavLink } from "react-router-dom";
import HamburgerMenu from "react-hamburger-menu";

const NavBar = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const closeNavbar = () => setCollapsed(true);

  return (
    <div
      className="container-fluid shadow position-sticky"
      style={{ top: 0, backgroundColor: "#0193DE", zIndex: 3000 }}
    >
      <div className="row justify-content-between align-items-center py-2 px-3">
        <div className="col col-auto">
          <NavLink to="/">
            <img
              src={sca_logo}
              height="75px"
              alt={"logo"}
              style={{ borderStyle: "double", borderColor: "white" }}
            />
          </NavLink>
        </div>
        <div className="col col-auto" style={{ cursor: "pointer" }}>
          <HamburgerMenu
            isOpen={!collapsed}
            menuClicked={toggleNavbar}
            width={27}
            height={16}
            strokeWidth={2}
            rotate={0}
            color="#ffffff"
            borderRadius={2}
            animationDuration={0.5}
          />
        </div>
      </div>
      <div className="row">
        <Collapse isOpen={!collapsed} className="w-100">
          <div>
            <div className="row py-4 justify-content-center align-items-center">
              <Link
                to="/kitdigital"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Kit Digital
              </Link>
              <Link
                to="/sistemasTactiles"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Sistemas táctiles
              </Link>
              <Link
                to="/sistemasCobro"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Sistemas de cobro
              </Link>
              <Link
                to="/videovigilancia"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Sistemas de Seguridad
              </Link>
              <Link
                to="/carteleriaDigital"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Carteleria Digital
              </Link>
              <Link
                to="/balanzas"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Balanzas
              </Link>
              <Link
                to="/registradoras"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Registradoras
              </Link>
              <Link
                to="/maquinariaIndustrial"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Maquinaria Industrial
              </Link>
              <Link
                to="/accesorios"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Accesorios y Consumibles
              </Link>
              {/* <Link to="/nuestrasInstalaciones" className="blueColor navItems my-2" onClick={closeNavbar}>Galeria</Link> */}
              <Link
                to="/contacto"
                className="blueColor navItems my-2"
                onClick={closeNavbar}
              >
                Contacto
              </Link>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default NavBar;
