import React from "react";
import { Link, Element } from 'react-scroll'
import video from "../assets/backgrounds/video.mp4"
import { AiFillCaretDown } from "react-icons/ai"

const VideoBanner = () => {
    return (
        <div className="container-fluid vh-100 p-0 m-0">
                <video autoPlay muted loop controls
                    style={{ objectFit: "cover"}}
                    className="position-absolute w-100 h-100"
                >
                    <source src={video} type="video/mp4"/>

                </video>
                <div className="w-100">
                        <div style={{ bottom: 0, left: 0 }} className="flex-center w-100 position-absolute mb-4">
                            <LinkToSlide to="sliderClientes"/>
                        </div>
                    </div>
                <Element name="sliderClientes"/>
        </div>
    )
}

const LinkToSlide = ({ to, offset = 600 }) => {
    return (
      <Link className="px-3 py-2 pointer text-secondary" to={to} offset={offset} spy={true} smooth={true}
            duration={800}>
            <AiFillCaretDown color="white" size="40px"/>
      </Link>
    )
  };

export default VideoBanner;
