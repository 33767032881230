import React from 'react';
import Card from "./Card"
import Carousel from "react-elastic-carousel";
import cortadora1 from "../assets/maquinariaIndustrial/cortadora_1.jpg"
import picadora1 from "../assets/maquinariaIndustrial/picadora_1.jpg"
import sierrasinfin1 from "../assets/maquinariaIndustrial/sierrasinfin_1.jpg"
import vaciocampana1 from "../assets/maquinariaIndustrial/vaciocampana_1.jpg"
import vacioExt1 from "../assets/maquinariaIndustrial/vacio_ext_1.jpg"
import empaquetadora1 from "../assets/maquinariaIndustrial/empaq_1.jpg"

const MaquinariaIndustrial = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2 },

  ];

  const elements1 = [
    "Cortadora integrada de acero inoxidable 18/8 con bastidor de aluminio anodizado.",
    "Cuchilla alemana, dureza 65 Hrc, baño de cromo duro de 14 micras.",
    "Afilador incorporado sistema automático.",
    "Motor con ventilación forzada, logrando una temperatura baja de funcionamiento.",
    "Aro protector cuchilla.",
    "Espesor de corte: 0-16 mm.",
  ]

  const elements2 = [
    "Picadora integrada de acero inoxidable 18/8.",
    "Motor monofásico 230V. o trifásico 230/400V. con ventilación forzada periférica, manteniendo una temperatura baja de funcionamiento.",
    "P-22/32 con Grupo Boca totalmente en acero inoxidable o Boca y Tuerca en aluminio anodinado, con sistema ENTERPRISE.",
    "P-82/98 con Grupo Boca totalmente en acero inoxidable, con sistema UNGER.",
    "Boca fácilmente desmontable para su limpieza.",
  ]

  const elements3 = [
    "Sierra de cinta para la industria cárnica y pesquera. Gran robustez, especial para todo tipo de grandes instalaciones. Fácil uso.",
    "Diseñada para facilitar su integración en diferentes líneas de procesado de corte.",
    "Sierra integrada de acero inoxidable 18/10.",
    "Poleas de acero inoxidable con doble pestaña.",
    "Tensado de cinta de proceso automático.",
    "Dispositivo de parada de cinta en 4 segundos.",
    "Pulsadores de marcha-paro, seta de emergencia, IP65.",
    "Dispositivo de seguridad en apertura puerta.",
    "No puesta en marcha involuntaria de la máquina.",
    "Fabricación corte mano derecha y mano izquierda.",
    "Fácil limpieza con agua a presión.",
    "Cuerpo de una sola pieza facilitando la limpieza sobre una capa uniforme.",
    "Limpiadores desmontables sin herramienta, para su fácil limpieza.",
    "Raspadores para la limpieza de poleas durante todo el proceso de corte.",
  ]

  const elements4 = [
    "Fabricada totalmente en acero inoxidable.",
    "Tapa transparente de la campana para una clara visualización en las Operaciones de envasado.",
    "Tapa para bombeada alcalde capacidad de la campana.",
    "La resistencia de calor es Fácilmente extraíble para una alcalde comodidad tanto a la hora de limpiar la campana como a la hora de cambiar sus propios componentes.",
    "Apertura fácil de la carcasa, con lo cual podremos Cambiar el aceite de la bomba fácilmente.",
    "Modo aspiración de aperturas con micro: de esta manera la maquina no tendrá tingún problema a la hora de absorción para el aire, nunca se podra quedar obstruia.",
  ]

  const elements5 = [
    "Envasadora profesional tanto para uso doméstico como para negocios y restaurantes con un diseño muy cuidado y versátil.",
    "Construida en ABS con triple barra de sellado de 32 cm.",
    "Operación automática y manual con control de sellado de 0 a 10 segundos.",
    "Filtro para partículas de polvo y líquido (perfectamente extraíble y salida lateral para expulsión de impurezas de la bomba.",
    "Medidor de depresión profesional para control de presión de vacío.",
    "Sistema de cierre (patentado) magnético que evita tener que apretar con fuerza la tapa a la hora de hacer el vacío.",
    "Maquina Italiana de calidad muy superior a las envasadoras habituales con una súper bomba de vacío auto lubricada de 650 w. de potencia y triple soldadura, capaz de trabajar de forma continua con bolsas gofradas de hasta 30cms de ancho, con los tarros normales de vacío habituales para las máquinas domésticas y con recipientes grandes de acero inoxidable tipo Vac Norm. de hasta 1/2 x 150 GN.",
  ]
  const elements6 = [
    "Al envasar el producto, se conserva la frescura y el sabor de todos los alimentos.",
    "Usada en: almacenes, supermercados, carnicerías, pescaderías, restaurantes, etc.",
    "Opciones de uso: cuchilla separada para corte o función de sellado mediante termostato.",
    "Fabricada en acero inoxidable.",
    "Soldadura de 450mm.",
  ]
  
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${cortadora1})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${Posterior})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Frontal})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "75%", backgroundPosition:"center"}}/> */}
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">CORTADORAS</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements1.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6 bg-white">
        <Carousel breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${picadora1})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${Posterior})`, backgroundSize: "60%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Frontal})`, backgroundSize: "60%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "60%", backgroundPosition:"center"}}/> */}
          </Carousel>
        </div>
        <div className="col-12 col-lg-6 py-4">
          <div className="row pt-5 pb-3 px-lg-5">
            <h1 className="">PICADORAS</h1>
          </div>
          <div className="row px-lg-5">
            <div className="col text-white px-2 m-0">
              {elements2.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${sierrasinfin1})`, backgroundSize: "65%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${Posterior})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Frontal})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "75%", backgroundPosition:"center"}}/> */}
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">SIERRAS SIN FIN</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements3.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6 bg-white">
        <Carousel breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${vaciocampana1})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${conjuntoIndustrial2})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${conjuntoIndustrial3})`, backgroundSize: "100%", backgroundPosition:"center"}}/> */}
          </Carousel>
        </div>
        <div className="col-12 col-lg-6 py-4">
          <div className="row pt-5 pb-3 px-lg-5">
            <h1 className="">ENVASADORA AL VACÍO CAMPANA</h1>
          </div>
          <div className="row px-lg-5">
            <div className="col text-white px-2 m-0">
              {elements4.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${vacioExt1})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${Posterior})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Frontal})`, backgroundSize: "75%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "75%", backgroundPosition:"center"}}/> */}
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">ENVASADORA AL VACÍO EXTERIOR</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements5.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6 bg-white">
        <Carousel breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${empaquetadora1})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
              {/* <Card style={{backgroundImage: `url(${conjuntoIndustrial2})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${conjuntoIndustrial3})`, backgroundSize: "100%", backgroundPosition:"center"}}/> */}
          </Carousel>
        </div>
        <div className="col-12 col-lg-6 py-4">
          <div className="row pt-5 pb-3 px-lg-5">
            <h1 className="">EMPAQUETADORA</h1>
          </div>
          <div className="row px-lg-5">
            <div className="col text-white px-2 m-0">
              {elements6.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
            </div>
          </div>
        </div>
      </div>
      </div>
</React.Fragment>
  );
};

export default MaquinariaIndustrial;