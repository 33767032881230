import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import NavBar from "./components/NavBar";
import SistemasTactiles from "./components/SistemasTactiles";
import Registradoras from "./components/Registradoras";
import Balanzas from "./components/Balanzas";
import CarteleriaDigital from "./components/CarteleriaDigital";
import MaquinariaIndustrial from "./components/MaquinariaIndustrial";
import SistemasSeguridad from "./components/SistemasSeguridad";
import SistemasCobro from "./components/SistemasCobro";
import Accesorios from "./components/Accesorios";
import Contacto from "./components/Contacto";
import Home from "./components/Home";
import Footer from "./components/footer/Footer";
import Cookies from "./components/Cookies";
import PrivacyPolicy from "./components/PrivacyPolicy";
import KitDigital from "./components/KitDigital";
// import NuestrasInstalaciones from "./components/NuestrasInstalaciones"
import { useLocation } from "react-router";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <NavBar />
      <Cookies />
      <Switch>
        <Route exath path="/kitdigital" component={KitDigital} />
        <Route exath path="/sistemasTactiles" component={SistemasTactiles} />
        <Route exath path="/sistemasCobro" component={SistemasCobro} />
        <Route exath path="/registradoras" component={Registradoras} />
        <Route exath path="/carteleriaDigital" component={CarteleriaDigital} />
        <Route exath path="/balanzas" component={Balanzas} />
        <Route
          exath
          path="/maquinariaIndustrial"
          component={MaquinariaIndustrial}
        />
        <Route exath path="/videovigilancia" component={SistemasSeguridad} />
        <Route exath path="/accesorios" component={Accesorios} />
        <Route exath path="/contacto" component={Contacto} />
        {/* <Route exath path="/nuestrasInstalaciones" component={NuestrasInstalaciones}/> */}
        <Route exath path="/politicaPrivacidad" component={PrivacyPolicy} />
        <Route exath path="/" component={Home} />
      </Switch>
      <Footer />
    </div>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default App;
