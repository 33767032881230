import React from 'react';
import Card from "./Card"
import Carousel from "react-elastic-carousel";
import gloryCI5 from "../assets/sistemasCobro/gloryCI-5.jpg"

const SistemasCobro = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2 },

  ];

  const elements1 = [
    "Cajón cobro efectivo automatico.",
    "Independientemente del tamaño de su negocio, necesita una solución de gestión de efectivo que sea flexible y aborde con éxito sus retos y objetivos específicos.",
    "El compacto cajón inteligente CI-5 evita que los empleados tengan que manejar efectivo en el punto de venta.",
    "Diseñado específicamente para establecimientos de retail, cuando se integra en un sistema TPV existente, el CI-5 automatiza de forma segura el manejo de efectivo en los puestos de caja, reduciendo el riesgo de que se produzcan errores y mermas, aumentando la productividad de los empleados y mejorando el servicio al cliente.",
  ]

  return (
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
      <Carousel className="carousel" breakPoints={breakPoints}>
          <Card style={{backgroundImage: `url(${gloryCI5})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
      </Carousel>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
        <div className="row pt-5 pb-3 px-lg-5">
          <h1 className="">GLORY CI-5</h1>
        </div>
        <div className="row px-lg-5">
          <div className="col text-white px-2 m-0">
          {elements1.map((text, key) => <div key={key}>
      {"✓ " + text}
      </div>)}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default SistemasCobro;