import React from 'react';
import Card from "./Card"
import Carousel from "react-elastic-carousel";
import Concord from "../assets/pantallas/concord.png"
import Frontal from "../assets/pantallas/Frontal.png"
import Lateral from "../assets/pantallas/Lateral.png"
import Posterior from "../assets/pantallas/Posterior.png"
import Software from "../assets/pantallas/soft.jpg"
import Retail1 from "../assets/icons/retail1.png"
import Retail3 from "../assets/icons/retail3.png"
import agoraofrece from "../assets/pantallas/agoraofrece.png"
import { FiCoffee } from "react-icons/fi" 
import { IoFastFoodOutline, IoBeerOutline, IoIceCreamOutline } from "react-icons/io5";
import { GiBread, GiConverseShoe, GiClothes, GiPerfumeBottle } from "react-icons/gi" 

const SistemasTactiles = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2 },

  ];

  const elements1 = [
    "Pantalla Tactil 15 Plana True Flat Resistiva (sin bordes entre pantalla y carcasa).",
    "Intel® Dual Core D525 1.8Ghz.",
    "Disco Duro 160GB SATA HDD 2.5.",
    "Memoria RAM 2GB DDR3 ampliable 4GB.",
    "Fanless.",
    "Soporte VESA.",
    "Frontal resistente a liquidos y suciedad.",
    "Carcasa y Peana de Aluminio.",
  ]

  const elements2 = [
    "1. TPV: Gestión de tickets sencilla y rápida.",
    "2. MULTIPUESTO: Gestión de tickets y cobros desde diferentes TPVs.",
    "3. COMANDERA WEB: Toma de comandas a través de un dispositivo móvil.",
    "4. CONTROL DE EFECTIVO: Integración con los principales fabricantes de máquinas de control de efectivo.",
    "5. APP ALMACÉN: Gestión de entrada de mercancía, inventario y regulación de stock desde un terminal móvil.",
    "6. MONITOR DE COCINA: Monitorización de los pedidos realizados.",
    "7. IMPRESORA DE COMANDAS: Impresión de las órdenes de preparación.",
    "8. ADMINISTRACIÓN: Acceso a la administración de tu negocio desde un navegador (PC, Smartphone, tablet...).",
    "9. ENLACE CCTV: Captura de forma sencilla todas las operaciones que se realizan en el establecimiento.",
    "10. PASARELA DE PAGOS: Enlace para las aplicaciones de pago con tarjeta.",
    "Y un backend integrado para realizar todo tipo de informes.",
  ]

  const elements3 = [
    "Funcionalidades básicas:",
    "Gestión de empleados.",
    "Gestión de catálogo.",
    "Control de stock.",
    "Diseño e impresión de etiquetas.",
    "Módulo de tallas y colores.",
    "Ofertas y promociones.",
    "Venta al peso.",
    "Gestión de ventas de cupones.",
    "Control de efectivo.",
    "Pasarela de pagos.",
    "Y un backend integrado para realizar todo tipo de informes.",
  ]
  
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${Concord})`, backgroundSize: "80%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Posterior})`, backgroundSize: "65%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Frontal})`, backgroundSize: "65%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Lateral})`, backgroundSize: "65%", backgroundPosition:"center"}}/>
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">TPV CONCORD 305</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements1.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6 bg-white">
        <Carousel breakPoints={breakPoints}>
            <Card style={{backgroundImage: `url(${agoraofrece})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
            <Card style={{backgroundImage: `url(${Software})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
        </Carousel>
        </div>
        <div className="col-12 col-lg-6 py-4">
          <div className="row pt-5 pb-3 px-lg-5">
            <h1 className="">SOFTWARE: AGORA RESTAURANT</h1>
          </div>
          <div className="row px-lg-5">
            <div className="col text-white px-2 m-0">
              {elements2.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
          <p style={{textAlign: "center", fontWeight: "bold", marginTop: "2%", fontSize: "19px"}}>Exprime tu negocio</p>
          <div className="col-12 col-md-auto mt-3 mt-md-0">
                <div className="nav justify-content-center">
                      <a className="d-flex align-items-center mx-3">
                        <IoFastFoodOutline color="white" size="23px"/>
                      </a>
                      <a className="d-flex align-items-center mx-3">
                        <IoBeerOutline color="white" size="23px"/>
                      </a>
                      <a className="d-flex align-items-center mx-3">
                        <IoIceCreamOutline color="white" size="23px"/>
                      </a>
                      <a className="d-flex align-items-center mx-3">
                        <FiCoffee color="white" size="23px"/>
                      </a>
                </div>
          </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-6 bg-white order-0 order-lg-1">
          <Carousel className="carousel" breakPoints={breakPoints}>
              <Card style={{backgroundImage: `url(${Retail1})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
              <Card style={{backgroundImage: `url(${Retail3})`, backgroundSize: "100%", backgroundPosition:"center"}}/>
          </Carousel>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">SOFTWARE: ÁGORA RETAIL</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
              {elements3.map((text, key) => <div key={key}>
          {"✓ " + text}
          </div>)}
          <p style={{textAlign: "center", fontWeight: "bold", marginTop: "2%", fontSize: "19px"}}>Exprime tu negocio</p>
          <div className="col-12 col-md-auto mt-3 mt-md-0">
                <div className="nav justify-content-center">
                      <a className="d-flex align-items-center mx-3">
                        <GiPerfumeBottle color="white" size="23px"/>
                      </a>
                      <a className="d-flex align-items-center mx-3">
                        <GiClothes color="white" size="23px"/>
                      </a>
                      <a className="d-flex align-items-center mx-3">
                        <GiConverseShoe color="white" size="23px"/>
                      </a>
                      <a className="d-flex align-items-center mx-3">
                        <GiBread color="white" size="23px"/>
                      </a>
                </div>
          </div>

              </div>
            </div>
          </div>
        </div>
      </div>
</React.Fragment>
  );
};

export default SistemasTactiles;